<template>
  <div>
    <div class="row">
      <div class="col-12 col-md-6">
        <h4 class="f-bold pt-2 pl-3 cl-primary text-left">
          Detail Warehouse User
        </h4>
      </div>
      <div class="col-12 col-md-6">
        <nav aria-label="breadcrumb">
          <ol
            class="breadcrumb float-right"
            style="background-color: transparent"
          >
            <li class="breadcrumb-item">
              <router-link to="/admin/dashboard">Dashboard</router-link>
            </li>
            <li class="breadcrumb-item">
              <router-link to="/admin/warehouse-users"
                >Warehouse Users</router-link
              >
            </li>
            <li class="breadcrumb-item active" aria-current="page">
              Detail Warehouse User
            </li>
          </ol>
        </nav>
      </div>
    </div>
    <div class="d-flex justify-content-around" v-if="loading">
      <loader-component></loader-component>
    </div>
    <div class="row gutters-sm" v-if="!loading">
      <div class="col-md-4 mb-3">
        <div class="card shadow p-3">
          <div class="card-body">
            <div class="d-flex flex-column align-items-center text-center">
              <img
                src="https://bootdey.com/img/Content/avatar/avatar7.png"
                alt="Admin"
                class="rounded-circle"
                width="150"
              />
              <div class="mt-3">
                <h4>{{ customer.name }}</h4>
                <p class="text-secondary mb-1">{{ customer.email }}</p>
                <p class="text-muted font-size-sm">
                  {{ customer.address }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-8">
        <div class="card shadow p-4 text-left">
          <div class="card-body">
            <div class="row">
              <div class="col-sm-3">
                <h6 class="mb-0 cl-secondary">Full Name</h6>
              </div>
              <div class="col-sm-9 text-secondary">{{ customer.name }}</div>
            </div>
            <hr />
            <div class="row">
              <div class="col-sm-3">
                <h6 class="mb-0 cl-secondary">Email</h6>
              </div>
              <div class="col-sm-9 text-secondary">{{ customer.email }}</div>
            </div>
            <hr />
            <div class="row">
              <div class="col-sm-3">
                <h6 class="mb-0 cl-secondary">Phone</h6>
              </div>
              <div class="col-sm-9 text-secondary">
                {{ customer.telephone }}
              </div>
            </div>
            <hr />
            <div class="row">
              <div class="col-sm-3">
                <h6 class="mb-0 cl-secondary">Gender</h6>
              </div>
              <div class="col-sm-9 text-secondary">
                {{ customer.gender == "M" ? "Male" : "Female" }}
              </div>
            </div>
            <hr />
            <div class="row">
              <div class="col-sm-3">
                <h6 class="mb-0 cl-secondary">Username</h6>
              </div>
              <div class="col-sm-9 text-secondary">
                {{ customer.username }}
              </div>
            </div>
            <hr />
            <div class="row">
              <div class="col-sm-3">
                <h6 class="mb-0 cl-secondary">Address</h6>
              </div>
              <div class="col-sm-9 text-secondary">{{ customer.address }}</div>
            </div>
            <hr />
            <div class="row">
              <div class="col-sm-3">
                <h6 class="mb-0 cl-secondary">Password Default</h6>
              </div>
              <div class="col-sm-9 text-secondary">mimo123</div>
            </div>
            <hr />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import LoaderComponent from "../../components/Loader.vue";
export default {
  name: "CustomerDetail",
  components: { LoaderComponent },
  props: ["id"],
  data() {
    return {
      loadNotification: "",
      customer: {},
      loading: true,
    };
  },

  mounted() {
    this.getDetailUser();
  },
  methods: {
    getDetailUser() {
      this.loading = true;
      const endpoint = "warehouse-detail/" + this.id;
      this.$api.get(endpoint, (status, data, message) => {
        console.log(status);
        if (status == 200) {
          this.loading = false;
          this.customer = data.warehouse;
          console.log(data);
        } else {
          this.loading = false;
          console.log(message);
        }
      });
    },
  },
};
</script>
